import mixpanel from "mixpanel-browser";
import { mixpanelProjectToken } from './secrets'
import * as User from './User'


export function initAndSetupUser(){
    init()
    setupUser()
}

export function init(){
    // mixpanel.init(mixpanelProjectToken, {debug: process.env.NODE_ENV === 'development'})
    mixpanel.init(mixpanelProjectToken, {debug: true})
}

export function setupUser(){
    if (!User.hasLiftriderUserId()){
        mixpanel.identify(User.newUserId)
        mixpanel.track('new user')
    } else {
        mixpanel.track('existing user')
    }
}