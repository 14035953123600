import React from 'react';
import './App.scss'
import { Splash } from './features/liftrider/Splash'
import { Cards } from './features/liftrider/Cards';
import { Colors } from './features/liftrider/Colors';
import { QualityConstruction } from './features/liftrider/QualityConstruction';
import { UserComments } from './features/liftrider/UserComments';
import { OurStory } from './features/liftrider/OurStory';
import { TechSpec } from './features/liftrider/TechSpec';
import { ContactInfo } from './features/liftrider/ContactInfo'
import { SubFooter } from './features/liftrider/SubFooter'
import { FreeShipping } from './features/liftrider/FreeShipping';
// import { SpringSale } from './features/liftrider/SpringSale';
// import { Christmas } from './features/liftrider/Christmas'
// import { BlackFriday } from './features/liftrider/BlackFriday';
// import { PresidentsDay } from './features/liftrider/PresidentsDay'

function App() {
  
  return (
    <>
      <Splash/>
      {/* <BlackFriday/> */}
      {/* <Christmas/> */}
      {/* <PresidentsDay/> */}
      <FreeShipping/>
      {/* <SpringSale/> */}
      <Cards/>
      <Colors/>
      <QualityConstruction/>
      <UserComments/>
      <OurStory/>
      <TechSpec/>
      <ContactInfo/>
      <SubFooter/>
    </>  
  );
}

export default App;
