import React from 'react'
import { Container, Form, Button } from 'react-bootstrap'
import * as SurveyController from './SurveyController'
import mixpanel from 'mixpanel-browser'

export function SurveyFeatures(){

    let features = []

    const checkChange = (e) => {
        features.push(e.target.name)
        // window.gtag('event', 'survey_features', { 'event_category': 'survey', 'event_label': e.target.name });
        // mixpanel.track('survey_features', {survey_feature: e.target.name})
    }

    const checkData = [
        { label: 'Sit back comfortably on the lift.', value:'lift_comfort', },
        { label: 'Safety on the lift.', value:'lift_safety', },
        { label: "Don't crush your lunch", value:'crush_lunch', },
        { label: 'Insulated hydration system', value:'hydration', },
        { label: 'Low profile balanced design', value:'balanced', },
        { label: 'Phone charging system', value:'phone', },
        { label: 'Lens safe compartments for goggles', value:'lens_safe', },
        { label: 'Camera safe compartment', value:'camera_safe', },
        { label: 'Helmet carry', value:'helmet_carry', },
        { label: 'Glove hook', value:'glove_hook', },
    ]

    const checks = checkData.map( d => 
            <Form.Check 
                className='text-muted mb-2'
                key={d.value}
                label={d.label}
                name={d.value}
                onChange={checkChange}
            /> 
    )

    const submit = () => {
        // window.gtag('event', 'survey_features', { 'event_category': 'survey', 'event_label': 'submitted' });
        mixpanel.track('survey_features', {features: JSON.stringify(features)})
        SurveyController.surveyFeaturesDone()
    }

    return (
        <Container className='shadow p-5 my-4 small '>
                <h2 className='mb-4 text-muted'>Thanks!</h2>
                <p>Please check the Liftrider features that interest you most:</p>
                {checks}
                <Button className='mt-3' onClick={submit}>Submit</Button>
        </Container>
    )
}