import React from 'react'
import { Carousel } from 'react-bootstrap'


export function HydrationCarousel(){

    const images = [
        {url: "assets/images/bladder/BiteValve.gif", interval: 6000},
        {url: "assets/images/bladder/bladder_600x400.png", interval: 2000}
    ]

    const carouselItems = images.map( (i, idx) =>
        <Carousel.Item key={idx} interval={i.interval}>
            <img className="d-block w-100" src={i.url} alt="carousel"/>
        </Carousel.Item>
    )

    return(
        <div className='order-last-if-above-sm' style={{maxWidth: '600px'}}>
            <Carousel fade className='shadow' variant='dark'>
                {carouselItems}
            </Carousel> 
        </div>
    )
}