import React from 'react'
import { BuyButton } from './BuyButton'
import { emailLink } from './ContactInfo'

export function OurStory(){

    const heading = 'Our Story'

    const copy =
        <>
            <p className='mb-4'>
                The idea for Liftrider originated when Farhad was riding the Red Dog lift at Squaw Valley and hated that his backpack was pushing him forward on the chair (Red Dog is super high and exposed). He shared the idea with his long-time friend Sani.
            </p>
            <p className='mb-4'>
                An obsessive engineer, Sani loves building great products. He cares deeply that the solution be elegant and novel. He rose to the challenge. 
            </p>
            <p className='mb-4'>
                After numerous prototypes, many of which Sani cut and sewed himself, Liftrider 1.0 had an initial run of 100 packs. We field tested those packs with the help of top skiers.
            </p> 
            <p className='mb-4'>
                Many reworks and a successful kickstarter campaign later, Liftrider 2.0 was born. Manufacturing required mulitple <a className='text-decoration-none' href='https://www.kickstarter.com/projects/1806017810/liftrider-worlds-best-ski-backpack/posts/1921608'>trips to China</a> to source the right materials and find the right factory. You can read much of the story in the updates of our <a className='text-decoration-none' href='https://www.kickstarter.com/projects/1806017810/liftrider-worlds-best-ski-backpack/posts'>kickstarter campaign</a>.
            </p>
            <p className='mb-4'>
                Got questions? Send us an {emailLink('email')}.
            </p>
            <BuyButton/>

        </>
    
    const saniAndFarhad = [
        {name: 'Sani Elfishawy', url:'assets/images/sani.jpg'},
        {name: 'Farhad Farzaneh', url:'assets/images/farhad.jpg'},
    ]

    const photos = saniAndFarhad.map( (sf, i) => 
        <div key={i}>
            <img 
                className="image-fluid m-auto d-block shadow" 
                src={sf.url}
                alt={sf.name}
                style={{maxWidth:'300px '}}
            />
            <div className='text-center mb-5'>
                <small className='text-muted mb-3'>{sf.name}</small>
            </div>
        </div> 
    )

    return (
        <div className='card container shadow my-4 p-5'>
                <h1>{heading}</h1>
                <div className="d-flex flex-row justify-content-around flex-wrap">
                    <div className="align-self-center card-copy grow-1 px-4 py-3 text-muted"
                         style={{minWidth: '300px'}}    
                    >
                        {copy}
                    </div>
                    <div className='grow-1 p-2 align-self-center'>
                        <div className='d-flex flex-column w-100'>{photos}</div>
                    </div>
                </div>
        </div>
    )
}
