import React from 'react'
import { Container, Button } from 'react-bootstrap'
import * as SurveyController from './SurveyController'
import mixpanel from 'mixpanel-browser'

export function SurveyIntro(){

    const handleOk = () => { 
        window.gtag('event', 'survey_intro_ok', { 'event_category': 'survey' });
        mixpanel.track('survey_intro_ok')
        SurveyController.surveyIntroOk() 
    }

    const handleNope = () => { 
        // window.gtag('event', 'survey_intro_nope', {
        //     'event_callback': function() { SurveyController.survey1Nope() },
        //     event_category: 'survey',
        // });

        mixpanel.track('survey_intro_nope', {send_immediately: true}, function(){ SurveyController.survey1Nope() } )
    }

    return (
        <Container className='shadow p-5 my-4 small text-muted'>
                <h2 className='mb-4 text-muted'>{SurveyController.REWARD} for some feedback?</h2>
                <p>Hello,</p>

                <p>This is Sani, creator of Liftrider.</p>

                <p>I would appreciate your help.</p>
            
                <p>We just redesigned our website and I value your feedback.</p>
            
                <p>May I offer you a {SurveyController.REWARD} reward at purchase to answer a few questions?</p>

                <p className='mt-5'>
                    <Button 
                        className='me-3' 
                        style={{width: '5rem'}}
                        onClick={handleOk}
                    >OK</Button> I'd be happy to help.
                </p>
            
                <p>
                    <Button  
                        className='me-3' 
                        style={{width: '5rem'}} 
                        variant='secondary'
                        onClick={handleNope}
                    >Nope</Button>  Take me to the shopping page.
                </p>

                <p><small style={{fontSize: '60%'}}>(Customer selected at random)</small></p>
        </Container>
    )
}