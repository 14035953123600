import mixpanel from "mixpanel-browser"

const url = new URL(window.location.href)
const urlParams = url.searchParams
const source = urlParams.get('utm_source')
const campaign = urlParams.get('utm_campaign')
const idPrefix = 'liftrider'

export const newUserId = `${idPrefix}_${source}_${campaign}_${Date.now()}.${window.performance.now()}`

export function hasLiftriderUserId(){
    return mixpanel.get_distinct_id().match(new RegExp(idPrefix)) != null
}