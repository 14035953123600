import React from 'react'
import { BuyButton } from './BuyButton'

const packPhotos = [ 
    'assets/images/backview-blue-site.jpg',
    'assets/images/sideview-blue-site.jpg',
]

const photos = packPhotos.map( (url, i) => 
        <img key={i}
            className="m-auto d-block " 
            style={{width:'150px'}}
            src={url}
            alt={url}
        />
)

export function TechSpec(){
    return (
        <div className='tech-spec container shadow my-4 p-5'>
            <h1>Technical Specifications</h1>

            <div className='d-flex flex-row flex-wrap'>{photos}</div>

            <div className="d-flex flex-row flex-wrap">
                <div className="copy-text px-2 pe-5" style={{flex: '1 1 300px'}}>

                    <h6>DIMENSIONS</h6>
                    22 x 13 x 4.5 in<br/>
                    58.6 x 33.2 x 12.75 cm

                    <h6>WEIGHT</h6>
                    25 oz (700 gm)

                    <h6>FIT</h6>
                    The pack is adustable and fits individuals 5'5" - 6'6" tall. If you are shorter or have a petit frame you will likely find the Liftrider to big. Please do not purchase. Hopefully we will have a smaller size in the future.<br/><br/>
                    Individuals over 230 lbs will likely Liftrider too small.

                    <h6>VOLUME</h6>
                    Top: 5.5 Liters<br/>
                    Bottom: 14.5 Liters<br/>
                    Total: 20 Liters

                    <h6>MATERIAL</h6>
                    1000 dernier heavy duty water-repellant polyurethane-coated nylon
                </div>

                <div className="copy-text px-2" style={{flex: '1 1 300px'}}>
                    <h6>HYDRATION</h6>
                    2 Liter low profile baffled bladder with large top opening and screw cap.<br/>
                    Insulated hose.<br/>
                    Bitevalve with on-off lever.<br/>
                    
                    <h6>POCKETS</h6>
                    <ul>
                        <li>Professional camera-bag quality padded top compartment with adjsutable dividers</li>
                        <li>Lower body compartment for water bladder and extra layer</li>
                        <li>Two lens-safe lined side pockets for drinks or goggles</li>
                        <li>Flat card or money slot</li>
                        <li>Insulated phone powerbank slot</li>
                        <li>Shoulder strap phone carry with sleeve for handwarmer to keep phone warm</li>
                        <li>Insulated shoulder strap hydration bite valve pocket with handwarmer sleeve to prevent freezing</li>
                        <li>Shoulder strap ski card pocket</li>
                    </ul>
                </div>
            </div>
            <div className='card-buy-button mt-5'>
                <BuyButton/>
            </div>
        </div>
    )
    
}