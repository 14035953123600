import React from "react";
import { BuyButton } from "./BuyButton";

export function SubFooter(){

    return (
        <div className='sub-footer text-center'>
            <div className='pt-4'>
                <small>
                    Copyright &copy; 2021 Liftrider Inc. <br/>
                    All rights reserved.
                </small>
            </div>
            <div className='pt-4'>
                <small>
                    Kickstarter Video Production by <br/>
                    StartMotionMEDIA
                </small>
            </div>
            <div className='pt-4 pb-5'>
                Seriously?<br/>
                You got all the way down here and haven't <a className='text-decoration-none' href={BuyButton.storeUrl}>ordered one?</a><br/>
                <a className='text-decoration-none' href={BuyButton.storeUrl}>Let's fix that now!</a> ;-)
            </div>

        </div>
    )
}