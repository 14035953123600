import React from 'react'

const phone = '415-294-1319'
const email = 'support@liftrider.me'
const address = '710 West California Way, Woodside, CA 94062'

export const emailLink = (text) => {
    return <a className='text-decoration-none' href={`mailto:${email}`}>{text}</a>
}

export const emailLinkAsString = (text) => {
    return `<a class='text-decoration-none' href=mailto:${email}>${text}</a>`
}
export function ContactInfo(){
    return (
        <div className='container contact-info shadow my-4 ps-5 pb-5 d-flex flex-row flex-wrap'>
            <div className='pt-5 pe-5' style={{flex: '1 1 250px'}}> 
                <h4>Contact</h4>
                <h6 className='m-0'>Address</h6>
                    {address}

                <h6 className='pt-3 m-0'>Email us</h6>
                    {emailLink(email)}<br/>
                    <i>(email is preferred)</i>

                <h6 className='pt-3 m-0'>Call us</h6>
                    {phone}<br/>
                    <i>(please leave a voicemail)</i>
            </div>
            <div className='pt-5 pe-5' style={{flex: '1 1 250px'}}> 
                <h4>Returns</h4>
                Please initiate any returns requests within 30 days of ordering. We will provide a refund for any <b>unused</b> packs <b>minus $30</b> for round-trip shipping and restocking. <a href='https://shop.liftrider.me/policies/refund-policy'> Details here.</a>
                <br/>
                <br/>
                Please contact us at the support@liftrider.me
            </div>
            <div className='pt-5 pe-5' style={{flex: '1 1 250px'}}> 
                <h4>Some Reviews</h4>
                <div className='mt-3'>
                    <a
                        className='link-secondary text-decoration-none'
                        target="_blank"
                        href="https://www.pugski.com/threads/liftrider-resort-backpack.4481"
                        rel="noreferrer noopener"
                    >
                        <h6 className='m-0'>Pugski review</h6>
                        <small>The notorious P.U.G runs the Liftrider through its paces</small>
                    </a>
                </div>
                <div className='mt-3'>
                    <a
                        className='link-secondary text-decoration-none'
                        target="_blank"
                        href="https://www.wired.com/story/best-skiing-and-snowboarding-gear/"
                        rel="noreferrer noopener"
                    >
                        <h6  className='m-0'>Wired review</h6>
                        <small>Wired Magazine gives the Liftrider a quick shout-out</small>
                    </a>
                </div>
            </div>
        </div>
    )
}