import React from 'react';
import { BuyButton } from './BuyButton';

// export function Card(props) {
export class Card extends React.Component{

  constructor(props){
    super(props)
    this.props = props

    this.imageStyles = {
      maxWidth: `${props.imageNaturalWidth}px` 
    }

    this.containerStyles = {
      backgroundColor: props.backgroundColor || 'inherit',
      color: props.color || 'inherit',
    }
  }


  imageOrVideo(){
    if (this.props.imageUrl){
      return (
          <img className="image-fluid m-auto d-block w-100 shadow" 
            src={this.props.imageUrl} 
            alt={this.props.altText}
            style={this.imageStyles}
          />
      )
    } else if (this.props.videoUrl) {
      return (
        <div className="ratio ratio-16x9 w-100">
          <iframe className='shadow'
                  src={this.props.videoUrl} 
                  title={this.props.altText}
                  frameBorder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowFullScreen/>
        </div>
      )
    } else if (this.props.component) {
      return this.props.component
    } else {
      return 'no video or image'
    }
  }

  render(){
    return (
      <div className='card container shadow p-5 my-4' style={this.containerStyles} >
        <h2><small className="card-sub-heading text-muted">{this.props.subheading}</small></h2>
        <h1 className='card-heading pb-4'>{this.props.heading}</h1>
        <div className="d-flex flex-row flex-wrap">
          <div dangerouslySetInnerHTML={{__html: this.props.copy}} className='card-copy pe-4 align-self-center text-muted'/>
          <div className='card-media align-self-center pb-4'> {this.imageOrVideo()} </div>
        </div>
        <div className='card-buy-button mt-5'>
          <BuyButton/>
        </div>
      </div>
  )}
}
