import React from 'react';
import * as Index from '../../index'

export function FreeShipping(){

    const handleClick = () => {
      Index.presidentsDay()
    }

    return (
        <div 
          className='free-shipping sale w-100 card container shadow py-4 my-4 ratio'
          style={{'--bs-aspect-ratio': '43.5%'}}
          onClick={handleClick}
          >
        </div>
    )
}