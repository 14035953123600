import React from 'react'
import { Container, Form } from 'react-bootstrap'
import * as SurveyController from './SurveyController'
import mixpanel from 'mixpanel-browser'

export function SurveyWillWontBuy(){

    const handleWillBuy = () => { 
        window.gtag('event', 'survey_will_buy', { 'event_category': 'survey' });
        mixpanel.track('survey_will_buy')
        SurveyController.willBuy() 
    }

    const handleWontBuy = () => { 
        window.gtag('event', 'survey_wont_buy', { 'event_category': 'survey' });
        mixpanel.track('survey_wont_buy')
        SurveyController.surveyWontBuy() 
    }

    return (
        <Container className='shadow p-5 my-4 small '>
            <h2 className='mb-4 text-muted'>Thank you!</h2>
            <p>Based on what you've seen what do you think of Liftrider?</p>
            <Form.Check 
                className='text-muted mb-3'
                type='radio'
                label='I am inclined to buy.'
                onClick={handleWillBuy}
            />
            <Form.Check 
                className='text-muted mb-3'
                type='radio'
                label='I will NOT buy one.'
                onClick={handleWontBuy}
            />
        </Container>
    )
}