import React from 'react'
import { HydrationCarousel } from "./HydrationCarousel"
import { HelmetCarousel } from "./HelmetCarousel"
import { emailLinkAsString } from './ContactInfo'

export const Content = [
  {
    subheading: 'The Perfect Ski Backpack',
    heading: "The pack you'll actually want to wear skiing",
    copy: "Liftrider is so slim, form-fitting and balanced you forget you are wearing it. But when you need a drink, an extra layer or a place to stash your goggles it is there for you. <br/> <br/> It is that simple.",
    imageUrl: 'assets/images/Blue_3_view_loaded.png',
    imageNaturalWidth: 500,
    altText: 'Lifrider loaded with gear',
    videoUrl: null,
    backgroundColor: null,
    color: null,
  }, 
  {
    subheading: 'Low Profile and Balanced',
    heading: 'Comfortable on the chair and a dream on the slopes',
    copy: "Liftrider's maximum depth of 4.5 inches, lets you sit back comfortably on the chairlift. <br/> <br/> On steeps or bumps, it spreads the weight across your core maintaining perfect balance.",
    imageUrl: 'assets/images/sideViewOrangeLg.png',
    imageNaturalWidth: 300,
    altText: 'Liftrider side view',
    videoUrl: null,
    backgroundColor: null,
    color: null,
  }, 
  {
    subheading: 'Incredibly Roomy',
    heading: 'Everything in its place',
    copy: "The padded camera-safe upper compartment sits above the back of the chair so your lunch doesn't get crushed. Lens-safe side pockets are perfect for your googles or a beer. The hydration bladder sits low and tight. There's a spot for your helmet, phone, gloves,  keys and much more.", 
    imageUrl: 'assets/images/fill-pack-animated.gif',
    imageNaturalWidth: 300,
    altText: 'Liftrider animated packing gif',
    videoUrl: null,
    backgroundColor: null,
    color: null,
  }, 
  {
    subheading: 'Hydration',
    heading: 'Custom hydration system',
    copy: "Hydration is vital to skiing well all day. Liftrider's low-profile, two-liter bladder rides snugly above your hips keeping the weight low. The double insulated hose runs to an insulated bite valve compartment in the shoulder strap. On really cold days drop a handwarmer in the special sleeve to prevent the bite valve from freezing.",
    component: <HydrationCarousel/>, 
    imageUrl: null,
    imageNaturalWidth: 600,
    altText: 'Hydration bladder image',
    videoUrl: null,
    backgroundColor: null,
    color: null,
  }, 
  {
    subheading: 'Patented Breakaway System',
    heading: 'Safe on the chairlift',
    copy: "A pack can be <a class='text-decoration-none' href='https://youtu.be/L5ioH3Vieyc'>dangerous</a> on the chairlift if it snags. Liftrider's clean design has no dangling straps. We designed a breakaway system that'll separate all straps so if it somehow gets caught on the chair, you can still get off safely.",
    imageUrl: null,
    altText: 'Video of backpack breakaway',
    videoUrl: 'https://www.youtube.com/embed/R2kWp3fkGoo?rel=0',
    backgroundColor: null,
    color: null,
  }, 
  {
    subheading: 'Insanity Straps',
    heading: "The world's smartest shoulder strap adjustment",
    copy: "Ever fumbled to release your straps with ski gloves? You'll love our patented push button strap release mechanism. <br/> <br/> And magnets cleverly hold the strap ends to prevent flapping.",
    imageUrl: null,
    imageNaturalWidth: null,
    altText: 'Video of strap adjustment.',
    videoUrl: 'https://www.youtube.com/embed/LOmP5obnCNI?rel=0',
    backgroundColor: null,
    color: null,
  }, 
  {
    subheading: 'Smart Phone Support',
    heading: 'Extra love for your phone',
    copy: "Keep our phone handy in the expandable shoulder pocket. Place a powerbank in the special insulated pocket in the back and charge the phone all day. Run earbuds out the top of the strap.",
    imageUrl: 'assets/images/battery-charger-animated.gif',
    imageNaturalWidth: 400,
    altText: 'Animated liftrider phone capabilities',
    videoUrl: null,
    backgroundColor: null,
    // backgroundColor: '#F1EFF0',
    color: null,
  }, 
  {
    subheading: 'Attention to Detail',
    heading: 'We thought of everything',
    copy: "We designed a method to securely carry your helmet so it doesn't swing. We added a special hook to hang your gloves when on the phone. There is a spot for you car keys, phone charger, and so much more.",
    component: <HelmetCarousel/>, 
    imageUrl: null,
    imageNaturalWidth: 600,
    altText: null,
    videoUrl: null,
    backgroundColor: null,
    color: null,
  }, 
  {
    subheading: 'A Blast From the Past',
    heading: 'How it all started',
    copy: "Years ago Liftrider got its start as a successful <a class='text-decoration-none' href='https://www.kickstarter.com/projects/1806017810/liftrider-worlds-best-ski-backpack'> Kickstarter campaign</a>. Check out our kickstarter video. <br/><br/> Also check out the <a class='text-decoration-none' href='https://www.kickstarter.com/projects/1806017810/liftrider-worlds-best-ski-backpack/posts/1921608'> adventure</a> of getting our first Liftriders made.",
    imageUrl: null,
    imageNaturalWidth: null,
    altText: 'Liftrider Kickstarter video',
    videoUrl: "https://www.youtube.com/embed/sQfd-ijkRRw?rel=0",
    backgroundColor: null,
    color: null,
  }, 
  {
    subheading: 'Adjust to Fit You',
    heading: 'How to adjust Liftrider to fit your body size',
    copy: `Liftrider is adjustable to fit skiers from 5'6" tall to 6'5" tall. This video shows how the adjustment works. <br><br>Note that customers shorter than 5'6" especially women with slight builds may find Liftrider too big.`,
    imageUrl: null,
    imageNaturalWidth: null,
    altText: 'Video showing how to adjust Liftrider for your body size',
    videoUrl: 'https://www.youtube.com/embed/2inUUvknrCI?rel=0',
    backgroundColor: null,
    color: null,
  }, 
  {
    subheading: 'Setup In Action',
    heading: 'How to setup all the features of your Liftrider',
    copy: `Here's how it all fits. This should help with setting up your pack but if you have more questions, just ${emailLinkAsString('email')} or call us!`,
    imageUrl: null,
    imageNaturalWidth: null,
    altText: 'Video showing how to setup Liftrider features',
    videoUrl: 'https://www.youtube.com/embed/0lxfTBz4klc?rel=0',
    backgroundColor: null,
    color: null,
  }, 
]


export const buyText = [
  'Buy now!',
  "I'm Sold!",
  'I only live once!',
  'Ship mine today!',
  "I'm gonna love it!",
  'Get mine now!',
  "How much is it?",
  'What am I waiting for?',
  "Let's light this candle!",
  "Ok already! I'm in!",
  "I'm in!",
  "Send me one!",
  "Let me choose my color!",
  "I can't wait to get mine!",
]

export const kickstarterCardContent = {
  subheading: 'A Blast From the Past',
  heading: 'How it all started',
  copy: "Years ago Liftrider got its start as a successful <a class='text-decoration-none' href='https://www.kickstarter.com/projects/1806017810/liftrider-worlds-best-ski-backpack'> Kickstarter campaign</a>. Check out our kickstarter video. <br/><br/> Also check out the <a class='text-decoration-none' href='https://www.kickstarter.com/projects/1806017810/liftrider-worlds-best-ski-backpack/posts/1921608'> adventure</a> of getting our first Liftriders made.",
  imageUrl: null,
  imageNaturalWidth: null,
  altText: 'Liftrider Kickstarter video',
  videoUrl: "https://www.youtube.com/embed/sQfd-ijkRRw?rel=0",
  backgroundColor: null,
  color: null,
} 

export const userComments = [
  {comment: "Oh! And not only was everyone's lunch smashed but mine, but it was COLD at Telluride last week (below zero cold) and their hoses were frozen and I had mine nice and snug wirh a hot pack in my shoulder. Perfect! I love the bag for so many reasons. I can bring sandwiches for the whole family when we ski as a group and the kids drink from my pack as well. Plenty for everyone. So well thought out. Looks great. Keep up the great work.", user: "Jeffrey Qaiyum, Chicago IL"}, 

  {comment: "Love LiftRider - it is MUCH better than anything else I have used for resort skiing! THANKS!", user: "Reagan W, Fort Worth TX"},

  {comment: "Works great- really comfortable - effortless in use and feel. I forget I have it on.  I gave a sales pitch to a couple lifties at Killington when they asked me to take it off before getting on the chair.  They let me wear it at the end of my presentation!", user: "Kip W, Newburyport MA "},

  {comment: "Got mine last week and we're inseparable.  I had stopped wearing backpacks after several reports of skiers trapped in chairs when the loose straps got caught in the chairs.  The tapered lower part works as advertised, allowing me to sit normally instead of on the edge of the chair.  With the magnetic strap holder, no more flopping straps. I love it.",  user: "Louis B, New London, NH "},

  {comment: "I used to hate digging around in my Camelbak to find the lipbalm.  With LiftRider everything is so organized and easy to get to",  user: "Mark L, Boulder, CO "},

  {comment: "The straps are awesome! The release system and magnets are genius.",  user: "Raj M, Charlotte, NC"},

  {comment: "I am VERY impressed with this company. The bag arrived today, looks great!  Thanks, Sani! Now that I've used it for a week, I love it! Very well designed and made, and the water system is simple and foolproof. Good pockets, zippers.",  user: "Rich S, Newton MA "},

  {comment: "Wore mine for the first time yesterday.  Better than expected. Finally a true everyday rider pack.  Comfortable, and a sincerely ingenious design.  My buddy is getting one after taking it for a couple runs! If you ride on a lift, you need this pack!",  user: "Frank C, Bangor PA "},

  {comment: "It is actually more comfortable to sit in a ski lift chair with it on, or any stiff back chair for that matter, than without, as long as there are no hard bulky contents. It doesn't make you lean forward on the ski lift like a conventional backpack.",  user: "Jeffrey C, Brentwood CA "},

  {comment: "I know you designed this with the go-hard on the mountain person in mind, but I am really excited as a Mom to use this pack.  I am up on the mountain with my 2 kids all the time and if I have those bars and oranges and water handy then we just keep having fun instead of making it back to the lodge and taking everything off.  If you are loading 3 people on the lift, especially young ones, you cannot be taking off your backpack and holding it too.", user: "Lisa G "},

  {comment: "I loved reading and feeling the passion you are pouring into this product. I cannot wait until next ski season to be able to experience and appreciate all of the blood, sweat, and tears you are dedicating to your vision! Thank you so much!", user: "on Ahlborn"},

  {comment: "This is an incredible update and we love your persistence. We sew and sell chew proof dog leashes and collars and we KNOW how hard it is to find someone who is equally passionate about the entire manufacturing process. Again....KUDOS!", user:  "Eric C"},

  {comment: "Hello Sani, Got to test drive the LiftRider for a whole week while skiing/snowboarding in the Pyrenees...  Awesomeness !  The pack feels like it's not there, even with the water bladder full.  Great job.", user: "Mark K"},
]