import React from 'react'
import { Container, Form } from 'react-bootstrap'
import * as SurveyController from './SurveyController'
import mixpanel from 'mixpanel-browser'

export function SurveyWontBuyWhy(){


    const handleChange = (e) => {
        window.gtag('event', 'survey_wont_buy_why', { 'event_category': 'survey', 'event_label': e.target.name });
        mixpanel.track('survey_wont_buy_why', { reason: e.target.name })
        SurveyController.SurveyWontBuyWhyDone()
    }

    return (
        <Container className='shadow p-5 my-4 small '>
            <h2 className='mb-4 text-muted'>Thanks!</h2>
            <p>I would not buy because:</p>
            <Form.Check 
                type='radio' 
                className='text-muted'
                label="I don't need a ski backpack" 
                onChange={handleChange}
                name='dont_need'/> 
            <Form.Check 
                type='radio' 
                className='text-muted'
                label="I am looking for a back-country pack" 
                onChange={handleChange}
                name='back_country'/> 
            <Form.Check 
                type='radio' 
                className='text-muted'
                label="I don't like the style" 
                onChange={handleChange}
                name='style'/> 
            <Form.Check 
                type='radio' 
                className='text-muted'
                label="I don't like the features" 
                onChange={handleChange}
                name='features'/> 
            <Form.Check 
                type='radio' 
                className='text-muted'
                label="Other reason" 
                onChange={handleChange}
                name='other'/> 
        </Container>
    )
}