import React from 'react'
import { Button } from 'react-bootstrap'
import * as Index from '../../index'

export function Colors(){

    const handleClick = () => {
        Index.goToStore()
    }

    return(
        <div className='container shadow my-4 p-5'>
            <h2><small className="text-muted">Colors</small></h2>
            <h1>Five spectacular colors</h1>
            <div className='text-muted'>
                Pick one of Liftrider's five gorgeous color schemes! 
                <br/>
                <br/>
                What is your favorite?
            </div>
            <div>
                <Button className='mt-5' size='lg'  onClick={handleClick}>Check out the Colors!</Button>
            </div>
        </div>
    )
}