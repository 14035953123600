import React from 'react'
import { Container, Button } from 'react-bootstrap'
import * as SurveyController from './SurveyController'
import mixpanel from 'mixpanel-browser'

export function SurveyThanksAndDone(){

    const handleClick = () => {
        // window.gtag('event', 'survey_done', {
        //     'event_callback': function() { SurveyController.surveyDone() },
        //     event_category: 'survey',
        // });
        mixpanel.track('survey_done', {}, {send_immediately: true}, function(){ SurveyController.surveyDone() })
    }

    return (
        <Container className='shadow p-5 my-4 small '>
            <h2 className='mb-4 text-muted'>Thanks you so much</h2>
            <p>Your {SurveyController.REWARD} reward will appear at checkout. Happy shopping!</p>
            <p className='mt-4'>
                <Button className='me-3' onClick={handleClick}>&nbsp;OK&nbsp;</Button>
            </p>
        </Container>
    )
}