import React from 'react';

export function Splash(props) {

  return (
      <div className='splash w-100 container shadow d-flex justify-content-center' style={{height: window.innerHeight * 0.45}}>
        <div className='titleBlock align-self-center'> 
          <div className='title text-center display-1'>LIFTRIDER</div>
          <div className='text-center'> The Worlds Greatest Ski Backpack </div>
        </div>
      </div>
  )
}