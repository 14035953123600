import React from 'react';
import ReactDOM from 'react-dom';
import mixpanel  from 'mixpanel-browser';
import * as serviceWorker from './serviceWorker';
import App from './App';
import * as SurveyController from './features/survey/SurveyController'
import * as Store from './Store'
import * as MixpanelHelper from './MixpanelHelper'

MixpanelHelper.initAndSetupUser()
mixpanel.track('home_page_visit_start')

export function renderWebsite() {
  ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

export function renderSurvey(){
  SurveyController.render()
}

export function handleBuyButtonClick(){
  if (shouldShowSurvey()){
    mixpanel.track('buy_button_to_survey')
    setSawSurvey()
    renderSurvey()
  } else {
    mixpanel.track(
        'buy_button_to_store', 
        {},
        {send_immediately: true}, 
        function(){ goToStore() }
    )
  }
}

function shouldShowSurvey(){
  if (getSawSurvey()){
    return false
  }

  const id = mixpanel.get_distinct_id()
  const lastDigit = parseInt(id.charAt(id.length - 1))
  if (lastDigit < 2){
    return true
  }
  return false
}

function setSawSurvey(){
  mixpanel.register({saw_survey: true})
}

function getSawSurvey(){
  return mixpanel.get_property('saw_survey')
}

export function blackFriday(){
  mixpanel.track(
    'black_friday',
    {},
    {send_immediately: true},
    function(){goToStore()}
  )
}

export function christmas(){
  mixpanel.track(
    'christmas',
    {},
    {send_immediately: true},
    function(){goToStore()}
  )
}

export function presidentsDay(){
  mixpanel.track(
    'presidentsDay',
    {},
    {send_immediately: true},
    function(){goToStore()}
  )
}

export function springSale(){
  mixpanel.track(
    'springSale',
    {},
    {send_immediately: true},
    function(){goToStore()}
  )
}

export function freeShipping(){
  mixpanel.track(
    'freeShipping',
    {},
    {send_immediately: true},
    function(){goToStore()}
  )
}

export function goToStore(){ window.location.href = Store.getStoreUrlWithParams() }

export function goToStoreSurveyDiscount(){ window.location.href = Store.getStoreUrlSurveyDiscountWithParams() }

renderWebsite()
// renderSurvey()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
