import React from 'react'
import { Carousel } from 'react-bootstrap'
import { BuyButton } from './BuyButton'

export function QualityConstruction(){

    const images = [
        "assets/images/rotation/equalSize/1000x500_1.png",
        "assets/images/rotation/equalSize/1000x500_2.png",
        "assets/images/rotation/equalSize/1000x500_3.png",
        "assets/images/rotation/equalSize/1000x500_4.png",
    ]

    const carouselItems = images.map( (i, idx) =>
        <Carousel.Item key={idx} interval={1000}>
            <img className="d-block w-100" src={i} alt="carousel"/>
        </Carousel.Item>
    )

    return(
        <div className='container shadow my-4 p-5'>
            <h2><small className="text-muted">Top Quality</small></h2>
            <h1>Gorgeous construction using the highest quality materials</h1>

            <Carousel fade className='my-4 shadow' variant='dark'>
                {carouselItems}
            </Carousel> 
            <BuyButton/>
        </div>

    )
}