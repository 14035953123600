import React from 'react'
import { Carousel } from 'react-bootstrap'


export function HelmetCarousel(){

    const images = [
        "assets/images/helmetGlovesKeys/glove1-700x350.png",
        "assets/images/helmetGlovesKeys/glove2-700x350.png",
        "assets/images/helmetGlovesKeys/glove3-700x350.png",
        "assets/images/helmetGlovesKeys/helmet-700x350.png",
        "assets/images/helmetGlovesKeys/powerbank-700x350.png",
    ]

    const carouselItems = images.map( (i, idx) =>
        <Carousel.Item key={idx} interval={1000}>
            <img className="d-block w-100" src={i} alt="carousel"/>
        </Carousel.Item>
    )

    return(
        <div className='order-last-if-above-sm' style={{maxWidth: '600px'}}>
            <Carousel fade className='shadow' >
                {carouselItems}
            </Carousel> 
        </div>
    )
}