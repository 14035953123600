import React from 'react'
import { Container, Form } from 'react-bootstrap'
import * as SurveyController from './SurveyController'
import mixpanel from 'mixpanel-browser'

export function SurveyChristmas2(){

    const onYes = () => {
        window.gtag('event', 'survey_christmas2_yes', { 'event_category': 'survey' });
        mixpanel.track('survey_christmas2_yes')
        SurveyController.surveyChristmas2Done()
    }

    const onNo = () => {
        window.gtag('event', 'survey_christmas2_no', { 'event_category': 'survey' });
        mixpanel.track('survey_christmas2_no')
        SurveyController.surveyChristmas2Done()
    }

    return (
        <Container className='shadow p-5 my-4 small '>
            <h2 className='mb-4 text-muted'>Great!</h2>
            <p>Would Liftrider make a good Christmas present?</p>
            <Form.Check className='text-muted' type='radio' label="Yes" onChange={onYes}/> 
            <Form.Check className='text-muted' type='radio' label="No" onChange={onNo}/> 
        </Container>
    )
}