import React from 'react'
import { userComments } from './Content'
import { BuyButton } from './BuyButton'

export function UserComments(){

    const comments = userComments.map( (c, i) => 
        <blockquote key={i} className="blockquote">
            <p className='mb-3'>{c.comment}</p>
            <footer className="blockquote-footer mb-5"><cite title="Source Title">{c.user}</cite></footer>
        </blockquote>
    )

    return(
      <div className='card container shadow my-4 p-5'>
            <h2><small className="text-muted">User Love</small></h2>
            <h1 className='mb-4'>A few of comments we've received</h1>
            <div className='copy-text text-muted shadow py-4 px-5 w-100' 
                 style={{border: '1px solid #C0BEBF', overflowY: 'scroll', height: window.innerHeight * 0.85}}>
                <small> {comments} </small>
            </div>
            <div className='card-buy-button mt-5'>
                <BuyButton/>
            </div>
      </div>
    )
}