export class BuyTextIndex{

    static _instance

    static getInstance(){
        if (!this._instance){
            this._instance = new BuyTextIndex()
        }
        return this._instance
    }

    constructor(){
        this.idx = 0
    }

    getIndex(){
        return this.idx
    }

    incrementIndex(){
        this.idx ++
    }
}