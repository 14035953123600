import React from 'react'
import { Button } from 'react-bootstrap'
import { buyText } from './Content'
import { BuyTextIndex } from './BuyTextIndex'
import * as Index from '../../index'
import mixpanel from 'mixpanel-browser'

export class BuyButton extends React.Component{

    constructor(props){
        super(props)
        this.bti = BuyTextIndex.getInstance()
    }

    getText(idx){
        return this.buttonText = buyText[idx % buyText.length ] 
    }

    onClick(e){ 
        mixpanel.track(
            'buy_button_click', 
            {buy_button_text: e.target.childNodes[1].data}, 
        )
        Index.handleBuyButtonClick() 
    }

    render() {
        const idx = this.bti.getIndex()
        this.bti.incrementIndex()
        return (
            <Button key={idx} size='lg' onClick={this.onClick} > {this.getText(idx)} </Button>
        )
    }
}   
