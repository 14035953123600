import React from 'react'
import { Container, Form } from 'react-bootstrap'
import * as SurveyController from './SurveyController'
import mixpanel from 'mixpanel-browser'

export function SurveyChristmas(){

    const handleYes = () => {
        window.gtag('event', 'survey_christmas_yes', { 'event_category': 'survey' });
        mixpanel.track('survey_christmas_yes')
        SurveyController.surveyChristmasYes()
    }

    const handleNo = () => {
        window.gtag('event', 'survey_christmas_no', { 'event_category': 'survey' });
        mixpanel.track('survey_christmas_no')
        SurveyController.surveyChristmasNo()
    }

    return (
        <Container className='shadow p-5 my-4 small '>
            <h2 className='mb-4 text-muted'>Thank you!</h2>
            <p>Is there a skier or snowboarder you usually buy Christmas presents for?</p>
            <Form.Check className='text-muted' type='radio' label="Yes" onClick={handleYes}/> 
            <Form.Check className='text-muted' type='radio' label="No" onClick={handleNo}/> 
        </Container>
    )
}