import React from 'react';
import { Card } from './Card'
import { Content } from './Content';

export function getCardForContent(content, idx){
  return (
      <Card
        key={idx}
        heading={content.heading}
        subheading={content.subheading}
        copy={content.copy}
        component={content.component}
        imageUrl={content.imageUrl}
        imageNaturalWidth={content.imageNaturalWidth}
        altText={content.altText}
        videoUrl={content.videoUrl}
    />
  )
}

export function Cards() {

  const cards = Content.map( (c, i) => getCardForContent(c, i))

  return (
    <>
      {cards}
    </>  
  );
}