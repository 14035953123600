import React from 'react'
import ReactDOM from 'react-dom'
import { SurveyIntro } from './SurveyIntro'
import { SurveyWillWontBuy } from './SurveyWillWontBuy'
import { SurveyPriceLess } from './SurveyPriceLess'
import { SurveyPriceMore } from './SurveyPriceMore'
import { SurveyWontBuyWhy } from './SurveyWontBuyWhy'
import { SurveyThanksAndDone } from './SurveyThanksAndDone'
import { SurveyFeatures } from './SurveyFeatures'
import * as Index from '../../index'
import { SurveyChristmas } from './SurveyChristmas'
import { SurveyChristmas2 } from './SurveyChristmas2'

/* 
SURVEY ORDER 
  - SurveyIntro -> ShoppingNoDiscount
  - SurveyFeatures
  - SurveyWillWontBuy -> SurveyWontBuyWhy -> SurveyThanksAndDone
  - SurveyPriceLess
  - SurveyPriceMore
  - SurveyChristmas -> SurveyThanksDone
  - SurveyChristmas2
  - SurveyThanksDone
  - ShoppingDiscount
*/

export const REWARD = '$5.00'

export function render(){ 
  renderComponent(<SurveyIntro/>) 
  // renderAllPages()
}

export function surveyIntroOk(){
  renderComponent(<SurveyFeatures/>)
}

export function surveyFeaturesDone(){
  renderComponent(<SurveyWillWontBuy/>)
}

export function survey1Nope(){
  Index.goToStore()
}

export function willBuy(){
  renderComponent(<SurveyChristmas/>)
}

export function surveyWontBuy(){
  renderComponent(<SurveyWontBuyWhy/>)
}

export function surveyPriceLessDone(){
  renderComponent(<SurveyChristmas/>)
}

export function surveyChristmasYes(){
  renderComponent(<SurveyChristmas2/>)
}

export function surveyChristmasNo(){
  renderComponent(<SurveyThanksAndDone/>)
}

export function surveyChristmas2Done(){
  renderComponent(<SurveyThanksAndDone/>)
}

export function SurveyWontBuyWhyDone(){
  renderComponent(<SurveyThanksAndDone/>)
}

export function surveyDone(){
  Index.goToStoreSurveyDiscount()   
}

function renderComponent(component){
  ReactDOM.render(
    <React.StrictMode>
      {component}        
    </React.StrictMode>,
    document.getElementById('root')
  );
  window.scrollTo(0, 0);
}

export function renderAllPages(){
  ReactDOM.render(
    <React.StrictMode>
      <SurveyIntro />
      <SurveyFeatures />
      <SurveyWillWontBuy/>
      <SurveyPriceLess />
      <SurveyPriceMore />
      <SurveyChristmas />
      <SurveyChristmas2 />
      <SurveyThanksAndDone />
    </React.StrictMode>,
    document.getElementById('root')
  )
}